<template>
  <slot :open="open" />
  <modal v-if="state.isOpen" :is-modal-open="state.isOpen" @close="close()" max-width="6xl">
    <div class="absolute top-0 right-0 pt-4 pr-4">
      <button @click="state.isOpen = false" type="button" class="text-gray-400 hover:text-gray-500 focus:outline-none focus:text-gray-500 transition ease-in-out duration-150" aria-label="Close">
        <icon-x class="w-6 h-6" />
      </button>
    </div>
    <div class="border-b pb-4 flex items-center justify-between">
      <div class="flex items-center">
        <span class="material-icons text-big-stone">swap_horiz</span>
        <h3 id="modal-title" class="text-lg font-bold ml-1">Machines Selector</h3>
      </div>
    </div>
    <div class="flex flex-wrap md:flex-nowrap md:space-x-4 py-2 md:py-4">
      <div class="w-full md:w-1/2">
        <div class="bg-gray-200 p-2 md:p-4 rounded-md">
          <h3 class="text-sm font-bold uppercase text-big-stone border-b border-big-stone pb-2 mb-4 border-opacity-25">Machine List</h3>
          <div class="flex items-center space-x-2">
            <input type="text" v-model="state.unSelectedMachineQuery" placeholder="Search..." class="border-big-stone border-opacity-20 rounded-md text-blumine placeholder-gray-500 focus:ring-sail focus:border-sail focus:outline-none focus:shadow-outline-sail transition duration-150 ease-in-out flex-1">
            <button @click="selectAllMachines" class="font-semibold text-sm px-2 py-2 rounded-lg bg-big-stone text-white hover:bg-blumine focus:outline-none focus:ring-2 focus:ring-sail focus:shadow-outline-sail active:bg-blumine transition duration-150 ease-in-out">Assign all</button>
          </div>
          <div class="mt-4 px-2">
            <div v-for="machine in unselected" :key="machine.location_id">
              <label>
                <input type="checkbox" v-model="state.selectedMachines" :value="machine.location_id" class="rounded">
                <span class="ml-2">{{ machine.name }}</span>
              </label>
            </div>
            <div v-if="unselected.length === 0">
              All machines are selected
            </div>
          </div>
        </div>
      </div>
      <div class="w-full md:w-1/2 mt-4 md:mt-0">
        <div class="bg-gray-100 p-2 md:p-4 rounded-md">
          <h3 class="text-sm font-bold uppercase text-big-stone border-b border-big-stone pb-2 mb-4 border-opacity-25">Selected</h3>
          <div class="flex items-center space-x-2">
            <input type="text" v-model="state.selectedMachinesQuery" placeholder="Search..." class="border-big-stone border-opacity-20 rounded-md text-blumine placeholder-gray-500 focus:ring-sail focus:border-sail focus:outline-none focus:shadow-outline-sail transition duration-150 ease-in-out flex-1">
            <button @click="unSelectAllMachines" class="font-semibold text-sm px-2 py-2 rounded-lg bg-big-stone text-white hover:bg-blumine focus:outline-none focus:ring-2 focus:ring-sail focus:shadow-outline-sail active:bg-blumine transition duration-150 ease-in-out">Un-Assign all</button>
          </div>
          <div class="mt-4 px-2">
            <div v-for="machine in selected" :key="machine.location_id">
              <label>
                <input type="checkbox" v-model="state.selectedMachines" :value="machine.location_id" class="rounded text-blumine">
                <span class="ml-2">{{ machine.name }}</span>
              </label>
            </div>
            <div v-if="selected.length === 0">
              No selected Machines
            </div>
          </div>
        </div>
      </div>
    </div>
    <div class="pt-4 md:border-t md:border-gray-100 flex justify-end space-x-4">
      <button class="inline-flex justify-center items-center py-2 px-4 border border-transparent rounded-md text-white font-semibold bg-green-500 hover:bg-green-600 focus:outline-none focus:ring-2 focus:ring-green-200 focus:shadow-outline-green-200 active:bg-green-700 transition duration-150 ease-in-out" @click="submit">Save</button>
    </div>
  </modal>
</template>

<script>
import { reactive, computed, watch } from 'vue'
import Modal from '@/components/Modal.vue'
import { IconX } from '@/components/icons'
import { sortAlpha } from '@/helpers/utils.js'

export default {
  name: 'ReportsRange',

  components: {
    Modal,
    IconX,
  },

  props: {
    machines: {
      type: Array,
      default: () => ([])
    },
    modelValue: {
      type: Array,
      default: () => ([])
    }
  },

  emits: ['update:modelValue', 'change'],

  setup(props, { emit }) {
    const state = reactive({
      isOpen: false,
      selectedMachines: props.modelValue,
      selectedMachinesQuery: '',
      unSelectedMachineQuery: '',
    })

    const open = () => state.isOpen = true

    const selected = computed(() => {
      const machines = props.machines.filter((machine) => {
        return state.selectedMachines.includes(machine.location_id)
          && machine.name.toLowerCase().includes(state.selectedMachinesQuery.toLowerCase())
      })

      return sortAlpha(machines, 'name')
    })

    const unselected = computed(() => {
      const machines = props.machines.filter((machine) => {
        return ! state.selectedMachines.includes(machine.location_id)
          && machine.name.toLowerCase().includes(state.unSelectedMachineQuery.toLowerCase())
      })

      return sortAlpha(machines, 'name')
    })

    const selectAllMachines = () => {
      state.selectedMachines = props.machines.map((machine) => machine.location_id)
    }

    const unSelectAllMachines = () => {
      state.selectedMachines = []
    }

    const submit  = () => {
      emit('update:modelValue', state.selectedMachines)
      emit('change', state.selectedMachines)
      state.isOpen = false
    }

    watch(
      () => props.modelValue,
      () => {
        state.selectedMachines = props.modelValue
      }
    )

    return {
      state,
      open,
      unselected,
      selected,
      submit,
      selectAllMachines,
      unSelectAllMachines,
    }
  }
}
</script>
