<template>
  <slot v-if="$slots.default" v-bind:open-picker="() => openPicker" />
  <div class="relative w-full" v-click-away="closePicker">

    <template v-if="!$slots.default">
      <button
        type="button"
        class="flex-row items-center text-big-stone font-semibold text-sm px-2 py-2 rounded-l-full rounded-r-full focus:outline-none focus:ring-2 hover:bg-sail hover:bg-opacity-50 focus:ring-inset focus:ring-blumine transition duration-150 hidden md:flex"
        @click="togglePicker"
      >
        <span>
          {{ modelValue.from }}
        </span>
        <span class="font-bold px-1">&dash;</span>
        <span>
          {{ modelValue.to }}
        </span>
        <span>
          <svg class="w-5 h-5 text-big-stone" xmlns="http://www.w3.org/2000/svg" height="24px" viewBox="0 0 24 24" width="24px" fill="currentColor"><path d="M0 0h24v24H0z" fill="none"/><path d="M16.59 8.59L12 13.17 7.41 8.59 6 10l6 6 6-6z"/></svg>
        </span>
      </button>
    </template>

    <div
      class="hidden md:flex absolute bg-white shadow-lg border-transparent mt-3 border rounded z-50 left-3/4 transform -translate-x-3/4"
      v-show="state.isPickerOpen"
    >
      <div class="flex w-64 p-4 gap-x-4">
        <div class="flex w-1/2 flex-col">
          <select-box label="From" v-model="modelValue.from">
            <option v-for="option in yearOptionsFrom" :key="option" :value="option">
              {{ option }}
            </option>
          </select-box>
        </div>
        <div class="flex w-1/2 flex-col">
          <select-box label="To" v-model="modelValue.to">
            {{ yearOptions }}
            <option v-for="option in yearOptions" :key="option" :value="option">
              {{ option }}
            </option>
          </select-box>
        </div>
      </div>
    </div>

    <!-- SELECTS FOR MOBILE -->
    <div class="flex md:hidden gap-x-4 m-2">
      <div class="flex w-1/2 flex-col">
        <select-box label="From" v-model="modelValue.from">
          <option v-for="option in yearOptionsFrom" :key="option" :value="option">
            {{ option }}
          </option>
        </select-box>
      </div>
      <div class="flex w-1/2 flex-col">
        <select-box label="To" v-model="modelValue.to">
          {{ yearOptions }}
          <option v-for="option in yearOptions" :key="option" :value="option">
            {{ option }}
          </option>
        </select-box>
      </div>
    </div>
  </div>
</template>

<script>
import { computed, reactive, watch } from 'vue'
import { directive } from 'vue3-click-away'
import date from '@/helpers/date'
import SelectBox from '@/components/form/Select.vue'

export default {
  name: 'DateRangePicker',

  directives: {
    ClickAway: directive,
  },

  components: {
    SelectBox,
  },

  props: {
    'model-value': {},
    submit: {},
    maxRange: {
      type: Number,
      default: null,
    },
    maxDate: {
      type: String,
      default: 'today',
    },
  },

  emits: ['submit', 'update:modelValue'],

  setup(props, { emit }) {
    const state = reactive({
      isPickerOpen: false,
      showRanges: false,
    })
    const minYear = 2005
    const maxYear = (new Date()).getFullYear()

    const togglePicker = () => state.isPickerOpen = !state.isPickerOpen
    const openPicker = () => state.isPickerOpen = true
    const closePicker = () => state.isPickerOpen = false

    const range = (start, stop, step) => Array.from(
      { length: (stop - start) / step + 1 },
      (_, i) => String(start + (i * step))
    )

    const yearOptions = range(maxYear, minYear, -1)
    const yearOptionsFrom = computed(() => range(Math.min(parseInt(props.modelValue.to), maxYear), minYear, -1))

    watch(
      () => [props.modelValue.from, props.modelValue.to],
      () => {
        emit('submit')
        closePicker()
      }
    )

    return {
      yearOptions,
      yearOptionsFrom,
      state,
      openPicker,
      closePicker,
      togglePicker,
      date,
    }
  }
}
</script>
