<template>
  <nav-secondary :routes="routes" />
</template>

<script>
import { computed } from 'vue'
import { useRoute } from 'vue-router'
import NavSecondary from '@/components/NavSecondary.vue'
import { IconRange, IconAnnual, IconCashout } from '@/components/icons/secondary'
export default {
  components: {
    NavSecondary
  },
  setup() {
    const route = useRoute()
    const routes = computed(() => {
      return [
        {
          label: 'Range Reports',
          to: { name: 'ReportsRange', query: route.query },
          desc: 'Sales data by date range',
          icon: IconRange,
        },
        {
          label: 'Annual Reports',
          to: { name: 'ReportsAnnual', query: route.query },
          desc: 'Month to month sales by year',
          icon: IconAnnual,
        },
        // {
        //   label: 'Hourly Report',
        //   to: { name: 'ReportsHourly', query: route.query },
        //   desc: 'Events per machine over the last 24 hours',
        //   icon:  IconHourly,
        // },
        {
          label: 'Cashout Report',
          to: { name: 'ReportsCashout', query: route.query },
          desc: 'Cashout events data',
          icon:  IconCashout,
        },
      ]
    })
    return {
      routes,
    }
  }
}
</script>
