<template>
  <!-- report toolbar start -->
  <form @submit.prevent="updateFilters" novalidate>
    <div class="flex flex-col md:flex-row md:justify-between md:items-center border-b pb-2">
      <div class="flex flex-row justify-between">
        <h1 class="font-bold text-xl sm:text-2xl">{{ title }}</h1>
        <div class="md:hidden">
          <select label="Product Type" v-model="state.filters.type" @change="updateFilters" class="appearance-none bg-none text-big-stone text-center text-xs font-semibold bg-sail bg-opacity-30 hover:bg-opacity-50 border-0 py-1 px-2 rounded-l-full rounded-r-full focus:outline-none focus:ring-2 focus:ring-inset focus:ring-blumine transition duration-150">
            <option value="all">Water + Ice</option>
            <option value="water">Water</option>
            <option value="ice">Ice</option>
          </select>
        </div>
      </div>

      <div class="flex flex-col sm:flex-row sm:items-center sm:justify-between md:divide-x">
        <!-- Slect Product + Select Range -->
        <div class="flex flex-row space-x-1 items-center">
          <div class="hidden md:flex">
            <select label="Product Type" v-model="state.filters.type" @change="updateFilters" class="appearance-none bg-none text-big-stone text-center text-xs font-semibold bg-sail bg-opacity-30 hover:bg-opacity-50 border-0 py-1 px-2 rounded-l-full rounded-r-full focus:outline-none focus:ring-2 focus:ring-inset focus:ring-blumine transition duration-150">
              <option value="all">Water + Ice</option>
              <option value="water">Water</option>
              <option value="ice">Ice</option>
            </select>
          </div>

          <date-range-picker
            v-if="!hideDateRange"
            v-model="state.filters.range"
            :max-date="maxPickerDate"
            :as-button="true"
          />
          <year-range-picker
            v-else
            v-model="state.filters.year_range"
            @submit="updateFilters"
          />
        </div>

        <!-- Select Machines  -->
        <div class="sm:pl-4 ml-2 h-10 flex items-center md:justify-center">
          <machine-selector
            v-slot="slotProps"
            :machines="machines"
            v-model="state.filters.machines"
            @change="updateFilters"
          >
            <div class="font-semibold text-big-stone text-sm hover:underline cursor-pointer" @click="slotProps.open()">
              {{ state.filters.machines.length }} machine/s selected
            </div>
          </machine-selector>
        </div>

      </div>
    </div>
  </form>
  <!-- report toolbar end  -->
</template>

<script>
import { reactive, watch } from 'vue'
import date from '@/helpers/date.js'
import DateRangePicker from '@/components/form/DateRange.vue'
import YearRangePicker from '@/components/form/YearRange.vue'
import MachineSelector from '@/components/reports/MachineSelector'


export default {
  name: 'ReportsRange',

  props: ['title', 'filters', 'machines', 'hideDateRange'],

  emits: ['update-filters'],

  components: {
    DateRangePicker,
    YearRangePicker,
    MachineSelector,
  },

  setup(props, { emit }) {
    const state = reactive({
      filters: props.filters
    })

    const updateFilters = () => {
      emit('update-filters')
    }

    watch(
      () => props.filters,
      () => {
        state.filters = props.filters
      }
    )

    watch(
      () => state.filters.range,
      () => {
        updateFilters()
      }
    )

    return {
      state,
      updateFilters,
      maxPickerDate: date.now().format('YYYY-MM-DD'),
    }
  }
}
</script>
